import React from "react"
import { PlainTitleWrapper, PlainTitle } from "../styles/components"

const NoImage = ({ title, taglineAbove, taglineBelow }) => {
  return (
    <PlainTitleWrapper>
      <PlainTitle>{title}</PlainTitle>
    </PlainTitleWrapper>
  )
}

export default NoImage
