import { useWindowSize } from "../../hooks/hooks"
import React, { useEffect, useRef } from "react"
import {
  ImageOverlay,
  ImageTitle,
  ImageTitleTaglineAbove,
  ImageTitleTaglineBelow,
} from "../styles/components"
import { gsap } from "gsap"

const fadeIn = targets => {
  if (targets != null)
    gsap.to(targets, {
      opacity: 1,
      duration: 0.5,
    })
}

const Overlay = ({ title, taglineAbove, taglineBelow }) => {
  const size = useWindowSize()
  const elementRef = useRef(null)
  useEffect(() => {
    fadeIn([elementRef.current])
  }, [elementRef, size.width])

  return (
    <ImageOverlay
      ref={elementRef}
      style={{
        opacity: 0,
      }}
    >
      {taglineAbove !== null && (
        <ImageTitleTaglineAbove>{taglineAbove}</ImageTitleTaglineAbove>
      )}
      {title !== null && <ImageTitle>{title}</ImageTitle>}
      {taglineBelow !== null && (
        <ImageTitleTaglineBelow>{taglineBelow}</ImageTitleTaglineBelow>
      )}
    </ImageOverlay>
  )
}

export default Overlay
