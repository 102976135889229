import React from "react"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

//* Device
import { device } from "../styles/device"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 */

const Image = ({ desktop, mobile, alt }) => {

  const images = withArtDirection(getImage(mobile), [
    {
      media: `${device.laptop}`,
      image: getImage(desktop),
    },
  ])

  return (
      <GatsbyImage
          image={images}
          alt={alt !== null ? alt : ""}
          objectFit="cover"
          objectPosition="50% 50%"
      />
  );
}

export default Image
