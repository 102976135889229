import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NoImage from "../components/image/no-image"
import Image from "../components/image/image"
import Overlay from "../components/image/overlay"
import {
  FragmentDivider,
  BelowImageTitle,
  BelowImageTitleTaglineAbove,
  BelowImageTitleTaglineBelow,
  ImageWrapper,
  BelowImageWrapper,
  Wrapper,
} from "../components/styles/components"

//* Font Awesome
import { MDXRenderer } from "gatsby-plugin-mdx"

const IndexPage = ({
  location,
  data: {
    homeMdx,
    featuredImageDesktop,
    featuredImageMobile,
    fragments,
    allJsonData,
  },
}) => {
  const { frontmatter, fields, body } = homeMdx
  const { title, description, taglineAbove, taglineBelow, meta, alt } = frontmatter
  const { language } = fields

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        lang={language}
        meta={meta}
        location={location}
      />
      {featuredImageDesktop === null && featuredImageMobile === null ? (
        <NoImage
          title={title}
          taglineAbove={taglineAbove}
          taglineBelow={taglineBelow}
        />
      ) : (
        <React.Fragment>
          <ImageWrapper>
            <Image desktop={featuredImageDesktop} mobile={featuredImageMobile} alt={alt} />
            <Overlay
              title={title}
              taglineAbove={taglineAbove}
              taglineBelow={taglineBelow}
            />
          </ImageWrapper>
          <BelowImageWrapper>
            {taglineAbove !== null && (
              <BelowImageTitleTaglineAbove>
                {taglineAbove}
              </BelowImageTitleTaglineAbove>
            )}
            {title !== null && <BelowImageTitle>{title}</BelowImageTitle>}
            {taglineBelow !== null && (
              <BelowImageTitleTaglineBelow>
                {taglineBelow}
              </BelowImageTitleTaglineBelow>
            )}
          </BelowImageWrapper>
        </React.Fragment>
      )}
      <Wrapper>
        <MDXRenderer>{body}</MDXRenderer>
        {fragments.edges.map((edge, key) => {
          return (
            <FragmentDivider key={"frag-mdx-" + key}>
              <MDXRenderer>{edge.node.body}</MDXRenderer>
            </FragmentDivider>
          )
        })}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`query HomeMdx($id: String!, $featuredImageDesktop: String!, $featuredImageMobile: String!, $fragments: [String!]) {
  homeMdx: mdx(fields: {id: {eq: $id}}) {
    fields {
      fileName
      language
    }
    id
    body
    frontmatter {
      slug
      title
      taglineAbove
      taglineBelow
      description
      alt
    }
  }
  featuredImageDesktop: file(relativePath: {eq: $featuredImageDesktop}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  featuredImageMobile: file(relativePath: {eq: $featuredImageMobile}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  fragments: allMdx(filter: {fields: {id: {in: $fragments}}}) {
    edges {
      node {
        fields {
          fileName
          language
        }
        id
        body
        frontmatter {
          title
          tags
          excerpt
        }
      }
    }
  }
}
`
export default IndexPage
